html,
body,
#root {
  height: 100%;
  background-color: #262730;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  overflow: hidden;
  background-color: #262730;
}

.resizebox {
  display: inline-block;
  background: #ccc;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  margin: 20px;
}

.drop-active {
  border-color: #aaa !important;
}

.drop-target {
  border-color: #fff !important;
  border-style: solid;
}
