
.moveable-control.moveable-direction {
  background-color: #f3c409 !important;
}

.moveable-line.moveable-direction {
  border: 1px solid #f3c409;
}

.moveable-control.moveable-direction.moveable-n,
.moveable-control.moveable-direction.moveable-s {
  width: 22px !important;
  height: 4px !important;
  border-radius: 2px !important;
  box-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.25) !important;
  margin-top: -3px !important;
  margin-left: -12px !important;
}

.moveable-control.moveable-direction.moveable-w,
.moveable-control.moveable-direction.moveable-e {
  width: 4px !important;
  height: 22px !important;
  border-radius: 2px !important;
  box-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.25) !important;
  margin-left: -3px !important;
  margin-top: -12px !important;
}
.moveable-line.moveable-rotation-line {
  display: none !important;
}

.moveable-control.moveable-rotation-control{
  border: 2px solid #f3c409 !important;
}

.moveable-control.moveable-rotation-control {
  background-color: #f3c409 !important;
  width: 23px !important;
  height: 23px !important;
  margin-top: -3px !important;
  margin-left: -13px !important;
  background-image: url('/src/assets/icons/recycle.svg') !important;
  background-size: 19px !important;
}

.fade-enter * {
  opacity: 0;
  transform: translateY(100%);
}
.fade-enter-active * {
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit *{
  opacity: 1;
  transform: translateY(0%);
}
.fade-exit-active * {
  opacity: 0;
  transform: translateY(-100%);
}
.fade-enter-active *,
.fade-exit-active * {
  transition: opacity 500ms, transform 500ms;
}